import styled, { keyframes } from 'styled-components'

const slideAnimation = (slideLength: number) => keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
         transform: translateX(calc(-250px * ${slideLength}))
    }
`

export const List = styled.div<{ $slideLength: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => `calc(250px * ${p.$slideLength * 3})`};
  height: 100%;
  animation: ${p => slideAnimation(p.$slideLength)} 20s linear infinite;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  min-width: 250px;
`

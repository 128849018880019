import Image from 'next/image'
import type { FC } from 'react'

import type { PaymentMethodsComponentProps } from '../PaymentMethodsBlock'
import { ImageWrapper, List } from './styles'

export const AnimatedPaymentMethods: FC<PaymentMethodsComponentProps> = ({ paymentMethods }) => {
  if (!paymentMethods) return

  const longArray = [...paymentMethods, ...paymentMethods, ...paymentMethods]
  const slideLength = paymentMethods.length

  return (
    <List $slideLength={slideLength}>
      {longArray?.map((method, index) => (
        <ImageWrapper key={index}>
          <Image alt={method.label} height="40" loading="lazy" src={method.image} width="120" />
        </ImageWrapper>
      ))}
    </List>
  )
}
